import React, { useCallback } from 'react';
import { Button, Label, Flex, IconButton, Tray, Para } from 'workspace-core-ui';
import { useOverlayTriggerState } from 'react-stately';
import useTranslation from '@hooks/useTranslation';
import getQuestionNameFromCurrentRoute from '@utils/getQuestionNameFromCurrentRoute';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { logItem } from '@slices/loggingSlice';
import CustomMdxRenderer from './CustomMdxRenderer';

const DemoWhyModalButton = ({
  buttonText,
  ...props
}: {
  buttonText: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentRoute } = useAppSelector(state => state.route);
  const overlayState = useOverlayTriggerState({});

  const TrayHeader = useCallback(
    () => (
      <Flex p={[4, 6, 6]}>
        <Flex flex={1}>
          <IconButton
            onPress={() => {
              overlayState.close();
            }}
            showOutline={false}
            iconName="close"
          >
            Close
          </IconButton>
        </Flex>
      </Flex>
    ),
    [overlayState],
  );

  return (
    <>
      <Button
        buttonSize="medium"
        variant="ghost"
        data-testid="why-demographics"
        onPress={() => {
          dispatch(
            logItem({
              collection_name: 'events',
              event_type: 'click',
              location: getQuestionNameFromCurrentRoute(currentRoute),
              target: 'why_demographics_open',
            }),
          );
          overlayState.open();
        }}
        {...props}
      >
        <Label
          sx={{
            textDecoration: 'underline',
          }}
          variant="l3"
        >
          {buttonText}
        </Label>
      </Button>
      <Tray
        title={buttonText}
        isOpen={overlayState.isOpen}
        onClose={() => {
          dispatch(
            logItem({
              collection_name: 'events',
              event_type: 'click',
              location: getQuestionNameFromCurrentRoute(currentRoute),
              target: 'why_demographics_close',
            }),
          );
          overlayState.close();
        }}
        isDismissable
        showBgImage={false}
        HeaderComponent={TrayHeader}
      >
        <Para as="span" variant="p3">
          <CustomMdxRenderer>{t('Demo Share Modal', true)}</CustomMdxRenderer>
        </Para>
      </Tray>
    </>
  );
};

export default DemoWhyModalButton;
